import { ISmartrrOrderWithCustomerRelationship } from "@smartrr/shared/entities/Order";

import { navigateToSubscriptionDetails } from "@vendor-app/utils/navigateWithShopInQuery";

export const navigateToSubscriptionDetailsFromOrder = (
  selectedResources: string[],
  orders: ISmartrrOrderWithCustomerRelationship[]
) => {
  for (const orderId of selectedResources) {
    const order = orders.find(order => order.id === orderId);

    if (!order || !order.custRel || !order.sts[0]) {
      continue;
    }

    const custRelShopifyId = order.custRel?.shopifyId;
    const subscriptionShopifyId = order.sts[0].shopifyId;

    if (!custRelShopifyId || !subscriptionShopifyId) {
      navigateToSubscriptionDetails(order.sts[0].id, true);
      continue;
    }

    navigateToSubscriptionDetails(subscriptionShopifyId, true);
  }
};
