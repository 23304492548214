import { SearchDropdownOptions } from "@vendor-app/app/_sharedComponents/TableSearch/libs";
import { TypedTableColumnType } from "@vendor-app/app/_sharedComponents/TypedTable/usePolarisTypedTable";
import { OrderHistoryTableColumnKeyType } from "@vendor-app/app/AdminRoute/AdminOrdersRoute/libs";

export const orderSearchOptions: SearchDropdownOptions = [
  { label: "Email/Name", value: "emailOrName", placeholder: "Search by email or name" },
];

export const orderHistoryColumns: TypedTableColumnType<OrderHistoryTableColumnKeyType> = {
  orderId: {
    name: "Order",
    paginatedValue: "orderId",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  orderProcessedDate: {
    name: "Date",
    paginatedValue: "orderProcessedDate",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  status: {
    name: "Fulfillment status",
    paginatedValue: "status",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  emailOrName: {
    name: "Customer",
    paginatedValue: "emailOrName",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  items: {
    name: "Items",
    paginatedValue: "items",
    filtering: true,
    sorting: false,
    disabled: false,
  },
  estimatedTotalNet: {
    name: "Total before tax",
    paginatedValue: "estimatedTotalNet",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  totalInCustomerCurrency: {
    name: "Cust. currency",
    paginatedValue: "totalInCustomerCurrency",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  smartrrOrderId: {
    name: "Smartrr Order ID",
    paginatedValue: "smartrrOrderId",
    filtering: true,
    sorting: true,
    disabled: false,
  },
};
