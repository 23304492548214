import { Button, ButtonGroup, Layout, LegacyCard, LegacyStack, useIndexResourceState } from "@shopify/polaris";
import { ExportMinor, ExternalMinor } from "@shopify/polaris-icons";
import { NO_OP_CALLBACK } from "@smartrr/shared/constants";
import { IOrderCSV, ISmartrrOrderWithCustomerRelationship } from "@smartrr/shared/entities/Order";
import { frontEndTabOpen } from "@smartrr/shared/utils/locationUtils";
import React, { useCallback, useState } from "react";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import {
  SelectionType,
  TypedTableColumnValue,
  usePolarisTypedTable,
} from "@vendor-app/app/_sharedComponents/TypedTable/usePolarisTypedTable";
import { useTableHandlers } from "@vendor-app/app/_sharedComponents/TypedTable/useTableHandlers";
import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import {
  HeaderContainer,
  PaginationContainer,
} from "@vendor-app/app/AdminRoute/AdminSubscriptionDetailsRoute/libs";
import { createAndDownloadCsv } from "@vendor-app/utils/createAndDownloadCsv";
import { exportOrdersToCsvRows, parseOrderForCSV } from "@vendor-app/utils/exportOrdersToCsvRows";
import { isRowSelected } from "@vendor-app/utils/isIndexTableRowSelected";
import { mapArrayEntityWithDelay } from "@vendor-app/utils/mapArrayEntityWithDelay";

import {
  OrderHistoryTableColumnKeyType,
  ValueSelectorForOrdersFilter,
  navigateToSubscriptionDetailsFromOrder,
  orderHistoryColumns,
  orderSearchOptions,
  parseOrdersForIndexTable,
} from "./libs";
import { ButtonBoldText } from "../../_sharedComponents/ButtonBold";
import { TablePage } from "../../_sharedComponents/TablePageWrapper";
import { TableSearch } from "../../_sharedComponents/TableSearch";
import { getQueryParamsForFilters } from "../../_sharedComponents/TableSearch/libs";
import { loadOrders } from "../../_state/actionCreators/order";

export const AdminOrdersRoute = () => {
  const { addToast } = useToast();
  const {
    data: orders,
    totalCount,
    isLoading,
    totalPages,
    pageSize,
  } = useSmartrrVendorSelector(state => state.orders);
  const { purchasables } = useSmartrrVendorSelector(state => state.purchasables);
  const activeOrg = useActiveOrganizationSelector();

  const { Table, Pagination, Filter, Sorting, Columns } = usePolarisTypedTable<OrderHistoryTableColumnKeyType>();

  const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(
    orders.map(order => ({ id: String(order.id) }))
  );

  const [tableProps, tableHandlers] = useTableHandlers("orderProcessedDate", "DESC");

  const [orderHistoryColumnsState, setOrderHistoryColumnsState] = useState(orderHistoryColumns);
  const [exportToCSVIsLoading, setExportToCSVIsLoading] = useState<boolean>(false);

  const exportToCsvWithLoadingState = useCallback(
    async (orders?: ISmartrrOrderWithCustomerRelationship[]) => {
      if (activeOrg) {
        setExportToCSVIsLoading(true);
        try {
          let rows;

          if (orders?.length) {
            rows = await mapArrayEntityWithDelay<ISmartrrOrderWithCustomerRelationship, IOrderCSV>({
              items: orders,
              chunkSize: 250,
              waitInterval: 500,
              filterFunction: order => parseOrderForCSV(order, activeOrg),
            });
          } else {
            rows = await exportOrdersToCsvRows(
              {
                filterIn: getQueryParamsForFilters(tableProps, tableProps?.filter)?.filterIn,
              },
              activeOrg
            );
          }
          rows && createAndDownloadCsv(rows, "Orders");
        } catch {
          addToast("Error exporting to CSV");
        } finally {
          setExportToCSVIsLoading(false);
        }
      }
    },
    [activeOrg, tableProps]
  );

  const handleExportAction = () => {
    exportToCsvWithLoadingState(
      allResourcesSelected ? undefined : orders.filter(order => selectedResources.includes(String(order.id)))
    );
    handleSelectionChange(SelectionType.All, false);
  };

  const promotedBulkActions = [
    {
      content: "View subscription details",
      onAction() {
        navigateToSubscriptionDetailsFromOrder(selectedResources, orders);
        handleSelectionChange(SelectionType.All, false);
      },
    },
    {
      content: "Export to CSV",
      onAction: handleExportAction,
      isLoading: exportToCSVIsLoading || isLoading,
      disabled: isLoading,
    },
  ];

  return (
    <React.Fragment>
      <TablePage
        narrowWidth
        title="Orders"
        primaryAction={
          <LegacyStack spacing="baseTight">
            <ButtonBoldText>
              <Button
                plain
                monochrome
                removeUnderline
                icon={ExportMinor}
                loading={exportToCSVIsLoading}
                onClick={() => exportToCsvWithLoadingState()}
              >
                Export to CSV
              </Button>
            </ButtonBoldText>
            <Button
              plain
              monochrome
              icon={ExternalMinor}
              onClick={() =>
                activeOrg ? frontEndTabOpen(`https://${activeOrg.myShopifyDomain}/admin/orders`) : NO_OP_CALLBACK
              }
            >
              View in Shopify
            </Button>
          </LegacyStack>
        }
      >
        <Layout>
          <Layout.Section>
            <LegacyStack spacing="loose" vertical>
              <LegacyCard>
                <LegacyCard.Section flush>
                  <HeaderContainer>
                    <div style={{ flex: 1 }}>
                      <TableSearch
                        key="autocomplete"
                        tableProps={tableProps}
                        selectOptions={orderSearchOptions}
                        loadData={loadOrders}
                        setFilter={tableHandlers.setFilter}
                        setPageNumber={tableHandlers.setPageNumber}
                      />
                    </div>

                    <ButtonGroup segmented>
                      <Filter
                        columns={orderHistoryColumns}
                        filter={tableProps.filter}
                        setFilter={tableHandlers.setFilter}
                        filterComponent={(field, value, handleSetFilter) => (
                          <ValueSelectorForOrdersFilter
                            field={field}
                            value={value}
                            setValue={newValue => handleSetFilter("update", field, newValue)}
                            purchasables={purchasables}
                          />
                        )}
                      />
                      <Columns
                        columns={orderHistoryColumnsState}
                        addToast={addToast}
                        setDisabledColumns={setOrderHistoryColumnsState}
                      />

                      <Sorting
                        columns={orderHistoryColumns}
                        orderByField={tableProps.orderByField}
                        orderByValue={tableProps.orderByValue}
                        setOrderByField={tableHandlers.setOrderByField}
                        setOrderByValue={tableHandlers.setOrderByValue}
                      />
                    </ButtonGroup>
                  </HeaderContainer>

                  <Table
                    selectable
                    columns={orderHistoryColumnsState}
                    data={parseOrdersForIndexTable(orders, activeOrg)}
                    loading={isLoading}
                    emptyStateText="No order history"
                    resourceName={{
                      singular: "order",
                      plural: "orders",
                    }}
                    itemCount={totalCount < pageSize ? totalCount : pageSize}
                    promotedBulkActions={promotedBulkActions.filter((x): x is any => x != null)}
                    sortable={Object.values(orderHistoryColumnsState)
                      .filter(v => !v.disabled)
                      .map(v => v.sorting)}
                    selectedItemsCount={allResourcesSelected ? "All" : selectedResources.length}
                    sortColumnIndex={Object.entries(orderHistoryColumnsState).findIndex(
                      ([key, value]) => value.paginatedValue === tableProps.orderByField && !value.disabled
                    )}
                    sortDirection={tableProps.orderByValue === "ASC" ? "ascending" : "descending"}
                    onSort={(headingIndex: number, direction: "ascending" | "descending") => {
                      tableHandlers.setOrderByField(
                        Object.entries<TypedTableColumnValue>(orderHistoryColumnsState).filter(
                          ([key, value]) => !value.disabled
                        )[headingIndex][0]
                      );
                      tableHandlers.setOrderByValue(direction === "ascending" ? "ASC" : "DESC");
                    }}
                    onSelectionChange={(selectionType, toggleType, selection) => {
                      handleSelectionChange(selectionType, toggleType, selection);
                    }}
                    isRowSelected={id => isRowSelected(id, selectedResources)}
                  />
                </LegacyCard.Section>
                <LegacyCard.Section subdued>
                  <PaginationContainer>
                    <Pagination
                      hasNext={totalPages > tableProps.pageNumber}
                      hasPrevious={tableProps.pageNumber != 1}
                      label={`Showing ${tableProps.pageNumber} of ${totalPages}`}
                      onNext={() => tableHandlers.setPageNumber(p => p + 1)}
                      onPrevious={() => tableHandlers.setPageNumber(p => p - 1)}
                    />
                  </PaginationContainer>
                </LegacyCard.Section>
              </LegacyCard>
            </LegacyStack>
          </Layout.Section>
        </Layout>
      </TablePage>
    </React.Fragment>
  );
};
